<template>
  <el-drawer size="50%" :visible="drawer" :with-header="false">
    <div style="height: 100vh" class="flex flex-col">
      <header
        class="p-5 border-b bg-gray-100 flex items-center justify-between"
      >
        <h1 class="text-xl font-semibold">
          <div class="mb-1">
            Заказ #{{ order && order.code }}
            <i v-if="loading" class="el-icon-loading"></i>
          </div>
          <el-button
            v-if="[2, 14, 17, 39, 40].includes(user.id)"
            @click="downloadWayills"
            :disabled="downloading"
            size="small"
            :icon="downloading ? 'el-icon-loading' : ''"
            round
            >Скачать накладной</el-button
          >
        </h1>
        <div
          @click="() => backToSales()"
          class="w-10 h-10 bg-gray-200 flex-shrink-0 cursor-pointer flex items-center justify-center rounded-3xl hover:bg-gray-300"
        >
          <i class="el-icon-close font-bold"></i>
        </div>
      </header>
      <div v-if="order" class="h-full overflow-y-auto px-5 mb-5">
        <a
          class="mt-4 block text-blue-400 underline"
          target="_blank"
          :href="
            'https://kaspi.kz/merchantcabinet/#/orders/details/' + order.code
          "
          >Перейти на каспи</a
        >
        <section class="mt-4">
          <p class="font-semibold text-xl mb-3">Информация о заказе</p>
          <ul class="border">
            <li
              class="flex border-b"
              v-for="(value, key) in orderInfo"
              :key="key"
            >
              <div class="border-r w-1/2 p-2">{{ value.label }}</div>
              <div class="p-2">{{ value.value }}</div>
            </li>
          </ul>
        </section>
        <section class="mt-4">
          <p class="font-semibold text-xl mb-3">Покупатель</p>
          <ul class="border">
            <li
              class="flex border-b"
              v-for="(value, key) in customerInfo"
              :key="key"
            >
              <div class="border-r w-1/2 p-2">{{ value.label }}</div>
              <div class="p-2">{{ value.value }}</div>
            </li>
          </ul>
        </section>

        <section class="mt-4">
          <p class="font-semibold text-xl mb-3">Состав заказа</p>
          <el-table border :data="products" stripe style="width: 100%">
            <el-table-column width="100" prop="code" label="Артикул">
            </el-table-column>
            <el-table-column prop="name" label="Название"> </el-table-column>
            <el-table-column width="100" prop="basePrice" label="Цена за ед.">
            </el-table-column>
            <el-table-column
              width="75"
              prop="quantity"
              label="Кол-во"
            ></el-table-column>
            <el-table-column width="100" prop="totalPrice" label="Цена">
              <template slot-scope="scope">
                {{ numberFormat(scope.row.totalPrice) }}тг
              </template>
            </el-table-column>
            <el-table-column
              v-if="[2, 14, 17, 39, 40].includes(user.id)"
              label="Настройки"
            >
              <template slot-scope="scope">
                <OrderItemSettings
                  :productCode="scope.row.code"
                  :productName="scope.row.name"
                  :orderId="orderId"
                  :orderCode="order && order.code"
                  :settings="{
                    imei: scope.row.imei,
                    guarantee: scope.row.guarantee,
                  }"
                />
              </template>
            </el-table-column>
          </el-table>
        </section>

        <section v-if="order.deliveryAddress" class="mt-4">
          <p class="font-semibold text-xl mb-3">Адрес доставки</p>
          <div>{{ order.deliveryAddress.formattedAddress }}</div>
        </section>
      </div>
      <footer
        class="flex justify-between items-center px-5 py-3 mt-auto border-t"
      >
        <!--        <el-button-->
        <!--          plain-->
        <!--          size="small"-->
        <!--          type="danger"-->
        <!--          @click="decline"-->
        <!--          icon="el-icon-delete"-->
        <!--        >-->
        <!--          Отменить-->
        <!--        </el-button>-->
        <!--        <el-button plain size="small" type="primary" @click="accept">-->
        <!--          Принять-->
        <!--        </el-button>-->
      </footer>
    </div>
  </el-drawer>
</template>

<script>
import { downloadWayills, getOrder } from "@/api/sales";
import { getPaymentModeTitle } from "@/helpers/payment-mode";
import { getDeliveryModeTitle } from "@/helpers/delivery-mode";
import { dateTimeFormat, dateFormat, numberFormat } from "@/helpers/formatter";
import OrderItemSettings from "@/components/OrderItemSettings";

export default {
  name: "orderView",
  components: {
    OrderItemSettings,
  },
  props: ["orderId"],
  data: () => ({
    downloading: false,
    loading: false,
    drawer: true,
    order: null,
    products: null,
    statuses: {
      APPROVED_BY_BANK: "Одобрен банком",
      ACCEPTED_BY_MERCHANT: "Принят на обработку продавцом",
      COMPLETED: "Завершён",
      CANCELLED: "Отменён",
      CANCELLING: "Ожидает отмены",
      KASPI_DELIVERY_RETURN_REQUESTED: "Ожидает возврата",
      RETURN_ACCEPTED_BY_MERCHANT: "Ожидает решения по возврату",
      RETURNED: "Возвращён",
    },
  }),
  computed: {
    orderInfo() {
      const result = {
        status: {
          label: "Статус заказа",
          value: null,
        },
        creationDate: {
          label: "Дата поступления заказа",
          value: null,
        },
        totalPrice: {
          label: "Сумма",
          value: null,
        },
        deliveryCost: {
          label: "Стоимость доставки для клиента",
          value: null,
        },
        paymentMode: {
          label: "Способ оплаты",
          value: null,
        },
        deliveryMode: {
          label: "Способ доставки",
          value: null,
        },
        plannedDeliveryDate: {
          label: "Планируемая дата доставки заказа",
          value: null,
        },
      };
      if (this.order) {
        Object.keys(result).forEach((item) => {
          result[item].value = this.order[item];
          if (item === "status") {
            result[item].value =
              this.statuses[this.order[item]] || this.order[item];
          }
          if (item === "paymentMode") {
            result[item].value = getPaymentModeTitle(this.order[item]);
          }
          if (item === "deliveryMode") {
            result[item].value = getDeliveryModeTitle(this.order[item]);
          }
          if (item === "creationDate") {
            result[item].value = dateTimeFormat(this.order[item]);
          }
          if (item === "plannedDeliveryDate" && this.order[item]) {
            console.log("this.order[item]", this.order[item]);
            result[item].value = dateFormat(this.order[item]);
          }
          if (item === "totalPrice") {
            result[item].value = `${numberFormat(this.order[item])}тг`;
          }
        });
      }
      return result;
    },
    customerInfo() {
      const result = {
        firstName: {
          label: "Имя",
          value: null,
        },
        lastName: {
          label: "Фамилия",
          value: null,
        },
        cellPhone: {
          label: "Телефон",
          value: null,
        },
      };
      if (this.order) {
        Object.keys(result).forEach((item) => {
          result[item].value = this.order.customer[item];
        });
      }
      return result;
    },
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    this.loading = true;
    const res = await getOrder(this.orderId);
    this.order = res.order.attributes;
    this.products = res.products;
    this.loading = false;
  },
  methods: {
    backToSales() {
      this.$router.push({ name: "sales" });
    },
    numberFormat(number) {
      return numberFormat(number);
    },
    decline() {},
    accept() {},
    async downloadWayills() {
      this.downloading = true;
      try {
        const res = await downloadWayills([this.orderId]);
        this.downloading = false;
        const domain = process.env["VUE_APP_DOMAIN"];
        this.$alert("Накладные успешно созданы", "Внимание", {
          confirmButtonText: "Скачать накладные",
          callback: () => {
            window.open(`${domain}/waybills/${res.result.fileHash}`, "_blank");
          },
        });
        console.log(res);
      } catch (e) {
        this.downloading = false;
      }
    },
  },
};
</script>

<style scoped></style>
