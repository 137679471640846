<template>
  <div class="relative" style="margin: -30px; height: calc(100vh - 60px)">
    <div
      v-if="[2, 14, 17, 39, 40].includes(user.id)"
      class="fixed top-2.5 left-32 z-50 flex items-center"
    >
      <div v-if="loading" class="mr-3">
        Идет загрузка ...
        <i class="el-icon-loading"></i>
      </div>
      <el-button
        :disabled="downloading || loading"
        :icon="downloading ? 'el-icon-loading' : ''"
        @click="downloadWayills"
        >Скачать накладные</el-button
      >

      <div class="ml-3 flex">
        <el-input
          v-model="orderCode"
          placeholder="Номер заказа"
          class="mr-2"
        ></el-input>
        <el-button
          @click="getOrderId"
          :disabled="searching || loading"
          :icon="searching ? 'el-icon-loading' : ''"
          >Поиск</el-button
        >
      </div>

      <el-select
        v-model="city"
        filterable
        clearable
        class="ml-2"
        placeholder="Город"
        @change="getOrders"
        :disabled="loading"
      >
        <el-option
          v-for="c in cities"
          :key="c.branch_code"
          :value="`1991000_${c.branch_code}`"
          :label="c.title"
        ></el-option>
      </el-select>
    </div>

    <div class="states flex h-full">
      <div
        v-for="state in states"
        :key="state"
        class="w-auto border-r"
        style="width: 20%"
      >
        <header
          class="text-center border-b bg-gray-100 py-2 font-medium flex items-center justify-center px-3"
        >
          <div>
            {{ state_title[state] }} ({{ getOrdersCount(state) }})
            <div>{{ numberFormat(getOrdersSum(state)) }} тг</div>
          </div>
          <el-button
            style="margin-left: auto"
            :disabled="downloading"
            :icon="downloading ? 'el-icon-loading' : ''"
            size="medium"
            @click="download"
            v-if="state === 'DELIVERY'"
            type="success"
            plain
            >Экспорт</el-button
          >
        </header>
        <main style="height: calc(100vh - 117px); overflow-y: auto" class="p-2">
          <ul v-if="orders[state]">
            <li
              v-for="order in orders[state]"
              :key="order.id"
              class="border rounded p-2 pb-0 mb-2 cursor-pointer hover:bg-gray-100"
              :class="{
                'bg-green-100 border-green-300': checkedOrders.includes(
                  order.id
                ),
              }"
            >
              <div class="flex justify-between items-center">
                <div>
                  {{ order.attributes.customer.firstName }}
                  {{ order.attributes.customer.lastName }}
                </div>
                <div class="text-xs text-gray-500">
                  {{ dateFormat(order.attributes.creationDate) }}
                </div>
              </div>
              <div class="text-blue-500">
                {{ order.attributes.customer.cellPhone }}
              </div>
              <div class="text-gray-400 text-xs mt-1">
                {{ order.attributes.totalPrice }} тг
              </div>
              <div
                :class="order.attributes.status"
                class="bg-gray-100 inline-block text-xs p-1 px-2 rounded mt-2 text-gray-500"
              >
                {{
                  statuses[order.attributes.status] || order.attributes.status
                }}
              </div>

              <div
                class="bg-gray-50 text-xs flex justify-between border-t mt-2 -mx-2 items-center"
              >
                <div
                  @click="selectOrder(order.id)"
                  class="w-full text-center border-r py-2"
                >
                  {{ checkedOrders.includes(order.id) ? "Убрать" : "Выбрать" }}
                </div>
                <div
                  @click="() => openOrder(order.id)"
                  class="w-full text-center"
                >
                  Открыть
                </div>
              </div>
            </li>
          </ul>
        </main>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  downloadOrders,
  downloadWayills,
  getOrderId,
  getOrders,
} from "@/api/sales";
import { format } from "date-fns";
import states from "@/helpers/states";
import { getBranches } from "@/api/branch";

export default {
  name: "index",
  data: () => ({
    loading: false,
    orders: {},
    checkedOrders: [],
    states: [
      "NEW",
      "SIGN_REQUIRED",
      "PICKUP",
      "DELIVERY",
      "KASPI_DELIVERY",
      "WAITING",
      "HANDED",
    ],
    // states: ["KASPI_DELIVERY"],
    statuses: {
      APPROVED_BY_BANK: "Одобрен банком",
      ACCEPTED_BY_MERCHANT: "Принят на обработку продавцом",
      COMPLETED: "Завершён",
      CANCELLED: "Отменён",
      CANCELLING: "Ожидает отмены",
      KASPI_DELIVERY_RETURN_REQUESTED: "Ожидает возврата",
      RETURN_ACCEPTED_BY_MERCHANT: "Ожидает решения по возврату",
      RETURNED: "Возвращён",
    },
    // states: ["DELIVERY"],
    state_title: states,

    downloading: false,
    orderCode: null,
    searching: false,

    cities: [],
    city: null,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    this.cities = await getBranches();
    await this.getOrders();
  },
  methods: {
    async getOrders() {
      this.loading = true;
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Загрузка",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      try {
        // let orders = {};
        for (let state of this.states) {
          if (!["HANDED", "WAITING"].includes(state)) {
            const res = await getOrders(state, this.city);

            this.$set(this.orders, state, res.data);
            // orders[state] = res.data;
            if (state === "KASPI_DELIVERY") {
              const waitingOrders = res.data.filter(
                (order) =>
                  !order.attributes.kaspiDelivery.courierTransmissionDate
              );
              this.$set(this.orders, "WAITING", waitingOrders);

              const handedOrders = res.data.filter(
                (order) =>
                  !!order.attributes.kaspiDelivery.courierTransmissionDate
              );
              this.$set(this.orders, "HANDED", handedOrders);
            }
          }
        }
        // this.orders = orders;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
      // loading.close();
    },
    async download() {
      this.downloading = true;
      await downloadOrders("DELIVERY");
      this.downloading = false;
    },
    dateFormat(timestamp) {
      return format(timestamp, "dd.MM HH:mm");
    },
    openOrder(orderId) {
      this.$router.push({ name: "order-view", params: { orderId: orderId } });
    },
    getOrdersCount(state) {
      if (this.orders[state] && this.orders[state].length) {
        return this.orders[state].length;
      }
      return 0;
    },
    async getOrderId() {
      this.searching = true;
      const order_id = await getOrderId(this.orderCode);
      this.searching = false;
      this.openOrder(order_id);
    },
    numberFormat(number) {
      return new Intl.NumberFormat().format(Math.round(number));
    },
    getOrdersSum(state) {
      if (this.orders[state] && this.orders[state].length) {
        return this.orders[state].reduce(
          (a, b) => b.attributes.totalPrice + a,
          0
        );
      }
      return 0;
    },
    selectOrder(orderId) {
      if (this.checkedOrders.includes(orderId)) {
        this.checkedOrders = this.checkedOrders.filter((id) => id !== orderId);
        return;
      }
      this.checkedOrders.push(orderId);
    },

    async downloadWayills() {
      this.downloading = true;
      try {
        const res = await downloadWayills(this.checkedOrders);
        this.downloading = false;
        const domain = process.env["VUE_APP_DOMAIN"];
        this.$alert("Накладные успешно созданы", "Внимание", {
          confirmButtonText: "Скачать накладные",
          callback: () => {
            window.open(`${domain}/waybills/${res.result.fileHash}`, "_blank");
          },
        });
        console.log(res);
      } catch (e) {
        this.downloading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.APPROVED_BY_BANK {
  background-color: #29bb89;
  color: #fff;
}
.ACCEPTED_BY_MERCHANT {
}
.COMPLETED {
  background-color: green;
}
.CANCELLED {
  background-color: #f54748;
}
.CANCELLING {
  background-color: #ffd8cc;
}
.KASPI_DELIVERY_RETURN_REQUESTED,
.RETURN_ACCEPTED_BY_MERCHANT {
  background-color: #ffe268;
}
.RETURNED {
  background-color: #51c2d5;
}
</style>
