import apiClient from "@/api/apiClient";

export const getOrders = async (state, city) => {
  const result = await apiClient.get("/api/sales/orders", {
    params: { state, city: city },
  });
  return result.data.orders;
};

export const downloadWayills = async (orders) => {
  const result = await apiClient.get("/api/sales/download-waybills", {
    params: { orders: JSON.stringify(orders) },
  });
  return result.data;
};

export const downloadOrders = async (state) => {
  const result = await apiClient.get("/api/sales/orders", {
    params: { state, action: "download" },
    responseType: "blob",
  });

  let type = result.headers["content-type"];
  let blob = new Blob([result.data], { type: type });
  let filename = "Заказы-на-доставке.xlsx";

  let URL = window.URL || window.webkitURL;
  let downloadUrl = URL.createObjectURL(blob);

  let a = document.createElement("a");
  if (typeof a.download === "undefined") {
    window.location = downloadUrl;
  } else {
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  setTimeout(function () {
    URL.revokeObjectURL(downloadUrl);
  }, 100); // cleanup

  return result.data;
};

export const getOrder = async (orderId) => {
  const result = await apiClient.get(`/api/sales/order/${orderId}`);
  return result.data;
};

export const getOrderId = async (orderCode) => {
  const result = await apiClient.get("/api/sales/orders", {
    params: { order_code: orderCode, action: "get_order_id" },
  });
  return result.data.order_id;
};

export const updateOrderItem = async (orderId, orderItem) => {
  const result = await apiClient.post(`/api/sales/order/${orderId}`, {
    item: orderItem,
  });
  return result.data;
};
