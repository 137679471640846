<template>
  <el-form class="order-item-settings" label-position="top">
    <el-form-item label="IMEI">
      <el-input size="medium" v-model="imei" placeholder="IMEI"></el-input>
    </el-form-item>
    <el-form-item label="Срок гарантии в месяцах">
      <el-input
        size="medium"
        type="number"
        v-model.number="guarantee"
        placeholder="Срок гарантии в месяцах"
      ></el-input>
    </el-form-item>

    <el-button
      :disabled="loading"
      :icon="loading ? 'el-icon-loading' : ''"
      type="primary"
      plain
      round
      size="medium"
      @click="save"
      >Сохранить</el-button
    >
  </el-form>
</template>

<script>
import { updateOrderItem } from "@/api/sales";

export default {
  name: "OrderItemSettings",
  props: {
    orderId: {
      type: String,
      required: true,
    },
    orderCode: {
      type: String,
      default: null,
    },
    productCode: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      imei: this.settings.imei,
      guarantee: this.settings.guarantee,
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        await updateOrderItem(this.orderId, {
          order_id: this.orderId,
          order_code: this.orderCode,
          product_code: this.productCode,
          product_name: this.productName,
          imei: this.imei,
          guarantee: this.guarantee,
        });
        this.$message.success("Успешно");
        this.loading = false;
      } catch (e) {
        this.$message.error("Ошибка в запросе");
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.order-item-settings {
  .el-form-item {
    margin-bottom: 10px;
    label {
      line-height: 1;
      font-size: 13px;
    }
  }
}
</style>
